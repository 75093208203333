import Utilities from './Utilities';

(function() {
  'use strict';

  const utils = new Utilities()

  window.ffn_analytics_utm_vars = window.ffn_analytics_utm_vars || {
    'utm_medium': utils.getUrlParameter('utm_medium'),
    'utm_source': utils.getUrlParameter('utm_source'),
    'utm_campaign': utils.getUrlParameter('utm_campaign')
  }

  function isObjectEmpty(obj) {
    return 0 === Object.keys(obj).length && obj.constructor === Object;
  }
  /**
   * Build the query string to attach to outbound URLs.
   * @param {Object} param_object The UTM object from local storage.
   */
  function buildUTMString(param_object) {
    let result = '';
    if (!isObjectEmpty(param_object)) {
      result = Object.keys(param_object)
        .map(key => [key, param_object[key]].map(encodeURIComponent).join('='))
        .join('&');
    }
    return result;
  }

  /**
   * Set query parameters for this user session, and save them to Session Storage.
   */
  function setParams() {
    const default_utm = window.ffn_analytics_utm_vars,
      queryParams = location.search.substring(1),
      storage = sessionStorage.getItem('utm_params');

    // Bail if we've already set UTM params during this user session.
    if (storage !== undefined && null !== storage) {
      return '';
    }

    let param_object = {};

    if (queryParams) {
      const params = queryParams.split('&');

      params.forEach(param => {
        const item = param.split('=');

        param_object[item[0]] = decodeURIComponent(item[1])
          .split('+')
          .join(' ');
      });
    }

    if (!param_object.utm_medium && default_utm.utm_medium) {
      param_object.utm_medium = default_utm.utm_medium;
    }

    if (!param_object.utm_source && default_utm.utm_source) {
      param_object.utm_source = default_utm.utm_source;
    }

    if (!param_object.utm_campaign && default_utm.utm_campaign) {
      param_object.utm_campaign = default_utm.utm_campaign;
    }

    sessionStorage.setItem('utm_params', JSON.stringify(param_object));
  }

  function parseTargetParams(queryParams) {
    const storage = JSON.parse(sessionStorage.getItem('utm_params'));

    if (queryParams) {
      const params = queryParams.split('&');

      params.forEach(param => {
        const item = param.split('=');

        storage[item[0]] = decodeURIComponent(item[1])
          .split('+')
          .join(' ');
      });
    }

    sessionStorage.setItem('utm_params', JSON.stringify(storage));
  }

  /**
   * Get the UTM parameters saved in Session Storage.
   */
  function getParams() {
    return sessionStorage.getItem('utm_params');
  }
  /**
   * Process the data from the target URL, and return component parts.
   * @param {Array} match The URL data captured on click.
   */
  function getURLData(match) {
    const params = JSON.parse(getParams());

    if (-1 < match[0].indexOf('save')) {
      const regex = new RegExp(/(http:\/\/|https:\/\/|(\?[\w\W]+))/g),
        strippedURL = match.input.replace(regex, '');

      return {
        base:
          strippedURL.lastIndexOf('/') === strippedURL.length - 1
            ? strippedURL.substring(0, strippedURL.lastIndexOf('/'))
            : strippedURL,
        utm: params,
        hash: ''
      };
    }

    let hashIndex = match.input.indexOf('#'),
      hash = match.input.substring(hashIndex, match.input.length);

    return {
      base: match[0],
      utm: params,
      hash: hash ? hash : ''
    };
  }

  /**
   *
   * @param {Element} target The clicked link.
   * @param {Array} match The URL data captured on click.
   */
  function passParams(target, match) {


    const urlData = getURLData(match);
    const UTMString = buildUTMString(urlData.utm);


    const hashString = urlData.hash;
    let newUrl = `https://${urlData.base}`;
    if (UTMString) {

      const isUnbounceLink = !!target.href.match(/\/clkn\//)
      if(isUnbounceLink) {
        // i have absolutely no idea why adding the trailing slash works here
        // it's explicitly stripped out of the original one, so I would rather not
        // mess with that. Who knows. This stuff is magic.
        newUrl = `${newUrl}/?${UTMString}`; 
      } else {
        newUrl = `${newUrl}?${UTMString}`; 
      }
      
    }
    if (hashString) {
      newUrl = `${newUrl}/${urlData.hash}`;
    }
    target.href = newUrl;
    target.setAttribute('target', '_blank');
  }

  /**
   * Builds a regular expression based on URLs entered in WordPress customizer.
   * @param {String} string A comma-separated list of URLs.
   */
  function buildRegex(string) {
    let regexArr = [];

    string.split(',').forEach(url => {
      let exp = url.trim();

      regexArr.push(exp.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'));
    });

    return new RegExp(regexArr.join('|'));
  }

  /**
   * Handles click events.
   * @param {Object} event The event object.
   */
  function handleClicks(event) {
    const target = utils.getAncestorBySelector(event.target, 'a'),
      regex = buildRegex('save.freedomdebtrelief.com, save.bills.com');

    event.stopPropagation();

    if (target && target.href && target.href.match(regex)) {
      // If UTMs have already been appended to the link, we'll need to parse those.
      if (0 <= target.href.indexOf('?')) {
        parseTargetParams(target.search.substring(1));
      }

      passParams(target, target.href.match(regex));
      return true;
    }

    return false;
  }

  document.addEventListener('DOMContentLoaded', setParams);
  document.addEventListener('click', handleClicks);
})();
